.basic-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  //general
  .button__container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    &.right {
      justify-content: right;
    }
    &.space-between {
      justify-content: space-between;
    }
    &.column {
      flex-direction: column;
      align-items: center;
    }

    .prev__box {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
    }
  }
  
  .radio-box-row {
    display: flex;
    flex-direction: column;
   
  }
  

  .radio-options {
    display: flex;
    justify-content: space-between; 
    gap: 121.3px;
    list-style: none;
    flex-direction: row;
  }
  
  .radio-option {
    display: flex;
    align-items: center;
    cursor: pointer;
   
  }
  
  

  .border-grey-200 {
    height: 1px;
    display: flex;
    width: 100%;
    background: $--gray-200;
  }
  .form__list p, .radio-options .text__box, .user-form__container p {
    font-weight: normal; /* Ensure text is not bold */
    color: #6B7280; /* Use a consistent gray color */
    font-size: 14px;
    line-height: 18px;
  }
  
  

  
  .radio-header {
    font-weight: normal; /* Ensure text is not bold */
    color: #6b7280; /* Match desired text color */
    font-size: 14px;
    line-height: 18px;
  }
  
  


  // methodology
  .guide-text__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px $--gray-200;
    h1 {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;
    }
    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;
    }
  }

  //complete용
  .complete-guide__container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 20px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $--gray-600;

      em {
        color: $--main-500;
      }
    }

    .img__box {
      margin-top: 36px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;

      .illust {
        display: flex;
        width: 193px;
        height: 280px;
        background: url('../images/bitmap@3x.png');
        background-size: cover;
      }
    }
  }

  .complete-phone__container {
    padding: 30px 0px;
    display: flex;
    border-top: solid 1px #e3e3e3;
    border-bottom: solid 1px #e3e3e3;
    width: 378px;

    .img__box {
      margin-right: 20px;
    }
    .text__box {
      display: flex;
      flex-direction: column;

      h2 {
        margin-top: 5px;
        margin-bottom: 20px;
      }
      span {
        font-size: 16px;
        line-height: 1.5;
      }
      em {
        line-height: 1.5;
        color: $--grey-500;
      }
    }
  }

  .guide-heading__container {
    margin-top: 40px;
    em {
      color: $--main-500;
    }
  }

  //user page
  //user-signup
  .headding-text__container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    & > header {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: solid 1px $--gray-200;
    }

    .right__box {
      display: flex;
      position: absolute;
      right: 0px;
    }
  }

  .singup-select__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 80px;
    & > ul {
      width: 100%;
      display: flex;
      li {
        flex: 1;
        display: flex;
        border-right: solid 1px $--gray-200;
        margin-right: 40px;
        &:last-child {
          border-right: 0px;
          margin-right: 0px;
        }

        .icon {
          width: 138px;
          height: 138px;
          &.signup-1 {
            background: url('../images/signup-1.png');
            background-size: 100% 100%;
          }
          &.signup-2 {
            background: url('../images/signup-2.png');
            background-size: 100% 100%;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 20px;
          h2 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  // signUp,login용
  .user-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 560px;
    & > header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: solid 1px $--grey-500;
      em {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--red-500;
      }
    }

    //terms

    .terms__list {
      display: flex;
      flex-direction: column;

      li {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        & > header {
          margin-bottom: 12px;
        }
        .text__viewer {
          height: 220px;
          overflow-y: auto;
          display: flex;
          padding: 12px;
          box-sizing: border-box;
          border: solid 1px $--gray-300;
          background-color: $--gray-0;

          p {
            white-space: pre-line;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: $--grey-500;
          }
        }
      }
    }
    .form__list {
      display: flex;
      flex-direction: column;
      & > article {
        margin-top: 32px;
      }
    }
  }

  .user-stepper__container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
  }

  .user-eamil__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 560px;

    .mail__box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image {
        display: flex;
        width: 128px;
        height: 128px;
        background: url('../images/email@3x.png');
        background-size: cover;
      }
    }

    .value__box {
      margin-top: 36px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $--gray-100;

      h2 {
        color: $--main-500;
      }
    }

    .guide__box {
      margin-top: 28px;
      display: flex;
      justify-content: center;
    }

    .info__box {
      margin-top: 36px;
      background-color: $--gray-100;
      display: flex;
      align-items: center;
      height: 90px;
      padding: 0px 20px;
      box-sizing: border-box;
      & > ul {
        display: flex;
        flex-direction: column;
        li {
          line-height: 1.5;
          letter-spacing: normal;
          color: $--gray-900;
          font-size: 16px;
          em {
            color: $--main-500;
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }
  }
  .login-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 360px;
    margin-bottom: 300px;

    .option__box {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;

      & > ul {
        display: flex;
        li {
          display: flex;
          align-items: center;
          &::after {
            display: flex;
            content: '';
            height: 20px;
            width: 1px;
            background: $--gray-200;
            margin-left: 8px;
            margin-right: 8px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--gray-900;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }

    .guide__text {
      display: flex;
      margin-bottom: 28px;
      span {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
        em {
          font-weight: bold;
        }
      }
    }
  }

  .styled-list ul {
    list-style-type: none;
    padding-left: 0; 
    justify-content: space-between;
    padding-bottom: 41px;
  }
  
  .styled-list li::before {
    content: "· ";
    color: #606369; 
    font-weight: 800; 
    margin: 0 8px;
 
  }
  

  //signout용
  .user-signout-form__container {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .grid__box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
    }
    .form__list {
      display: flex;
      flex-direction: column;
      .guide__box {
        margin-top: 0px;
        h2 {
          margin-bottom: 16px;
        }
        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--grey-500;
        }
      }
      & > article {
        margin-top: 32px;
      }
    }
    .info__box {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 24px;
      box-sizing: border-box;
      background-color: $--gray-100;

      & > ul {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        li {
          display: flex;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $--grey-500;
          &::before {
            content: '·';
            margin-right: 3px;
            display: flex;
            font-size: 14px;
          }
        }
      }
    }
  }

  .board__container {
    margin-top: 40px;
    display: flex;
    width: 100%;
  }

  .contact-us-form__container {
    display: flex;
    width: 100%;
    padding-top: 33px;
    align-items: center;
    .form__box {
      width: 435px;
      margin-right: 32px;

      .guide-phone__box {
        display: flex;
        flex-direction: column;
        background: #f6f9fd;
        padding: 32px 20px;
        box-sizing: border-box;
        border: solid 1px $--gray-200;
        box-sizing: border-box;
        position: relative;
        & > header {
          display: flex;
          align-items: flex-start;
          h3 {
            color: $--gray-900;
          }
        }
        .time__box {
          display: flex;
          flex-direction: column;
          margin-top: 12px;
          margin-bottom: 10px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            & > header {
              display: flex;
              align-items: center;
              min-width: 84px;
              .ico {
                margin-right: 4px;
              }
            }
            span {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--gray-900;
            }
          }
        }
        .illust {
          display: flex;
          position: absolute;
          width: 202px;
          height: 152px;
          background: url('../images/img-faq-pc-02@3x.png');
          background-size: cover;
          right: -35px;
          bottom: 0px;
        }
      }
      .guide-text__box {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 47px;
        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 1.43;
          color: $--gray-900;
          &.gray {
            color: $--gray-600;
          }
          em {
            font-weight: bold;
          }
        }
      }

      & > article {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .aggree__box {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        .option__box {
          margin-left: 15px;
          display: flex;
          height: 100%;
          align-items: center;
          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--main-500;
            text-decoration: underline;
          }
        }
      }
    }
    .map__box {
      flex: 1;
      display: flex;
      flex-direction: column;
      .daum-map__box {
        height: 665px;
        margin-bottom: 20px;
      }

      .text__box {
        display: flex;
        flex-direction: column;
        span {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
    }
  }
  .grievance__container {
    display: flex;
    padding-top: 33px;
    justify-content: center;
    .form__box {
      width: 650px;
      .guide-phone__box {
        display: flex;
        flex-direction: column;
        background: #f6f9fd;
        padding: 32px 20px;
        box-sizing: border-box;
        border: solid 1px $--gray-200;
        box-sizing: border-box;
        position: relative;
        & > header {
          display: flex;
          align-items: flex-start;
          h3 {
            color: $--gray-900;
          }
        }
        .time__box {
          display: flex;
          flex-direction: column;
          margin-top: 12px;
          margin-bottom: 10px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            & > header {
              display: flex;
              align-items: center;
              min-width: 84px;
              .ico {
                margin-right: 4px;
              }
            }
            span {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--gray-900;
            }
          }
        }
        // .illust {
        //   display: flex;
        //   position: absolute;
        //   width: 202px;
        //   height: 152px;
        //   background: url('../images/img-faq-pc-02@3x.png');
        //   background-size: cover;
        //   right: -35px;
        //   bottom: 0px;
        // }
      }
      .guide-text__box {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 47px;
        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          line-height: 1.43;
          color: $--gray-900;
          &.gray {
            color: $--gray-600;
          }
          em {
            font-weight: bold;
          }
        }
      }

      & > article {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .aggree__box {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        .option__box {
          margin-left: 15px;
          display: flex;
          height: 100%;
          align-items: center;
          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--main-500;
            text-decoration: underline;
          }
        }
      }
    }
    .map__box {
      flex: 1;
      display: flex;
      flex-direction: column;
      .daum-map__box {
        height: 665px;
        margin-bottom: 20px;
      }

      .text__box {
        display: flex;
        flex-direction: column;
        span {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
    }
  }

  .notice__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .serach__box {
      display: flex;
      align-items: center;
      margin-top: 36px;
      margin-bottom: 24px;
    }
    .board__box {
      margin-top: 28px;
      margin-bottom: 36px;
    }
  }

  .faq__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 36px;
    .option__box {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      overflow: hidden;
      border: solid 1px $--gray-200;
      box-sizing: border-box;

      .guide__box {
        display: flex;
        .inquiry__box {
          width: 687px;
          display: flex;
          flex-direction: column;
          background: #f6f9fd;
          padding: 32px 20px;
          padding-bottom: 20px;
          box-sizing: border-box;
          border-bottom: solid 1px $--gray-200;
          position: relative;
          border-right: solid 1px #eeeeee;
          span {
            margin-top: 8px;
            margin-bottom: 8px;
            color: $--gray-900;
            font-size: 14px;
          }

          .illust {
            display: flex;
            position: absolute;
            width: 310px;
            height: 152px;
            background: url('../images/img-faq-pc-01@3x.png');
            background-size: cover;
            right: 0px;
            bottom: 0px;
          }
        }
        .phone__box {
          flex: 1;
          display: flex;
          flex-direction: column;
          background: #f6f9fd;
          padding: 32px 20px;
          padding-bottom: 20px;
          box-sizing: border-box;
          border-bottom: solid 1px $--gray-200;
          position: relative;

          & > header {
            display: flex;
            align-items: flex-start;
            h3 {
              color: $--gray-900;
            }
          }
          .time__box {
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            margin-bottom: 10px;
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 6px;
              & > header {
                display: flex;
                align-items: center;
                min-width: 84px;
                .ico {
                  margin-right: 4px;
                }
              }
              span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: $--gray-900;
              }
            }
          }
          .illust {
            display: flex;
            position: absolute;
            width: 202px;
            height: 152px;
            background: url('../images/img-faq-pc-02@3x.png');
            background-size: cover;
            right: 0px;
            bottom: 0px;
          }
        }
      }

      .serach__box {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      .category__box {
        display: flex;
        justify-content: center;
        margin-top: 28px;
        margin-bottom: 28px;
        padding: 0px 180px;
        box-sizing: border-box;
        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;
            box-sizing: border-box;
            border-radius: 4px;
            border: solid 1px $--main-300;
            background-color: $--main-100;
            height: 28px;
            margin-right: 12px;
            margin-bottom: 12px;
            &:last-child {
              margin-right: 0px;
            }

            &.on {
              background: $--main-500;
              span {
                color: #fff;
              }
            }
            span {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--main-500;
            }
          }
        }
      }
    }

    .board__box {
      margin-top: 28px;
      margin-bottom: 36px;
    }
  }

  .search__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 20px;

    &.between {
      justify-content: space-between;
    }

    .input__box {
      display: flex;
      align-items: center;
    }
    .date__box {
      display: flex;
      align-items: center;
    }
    .select__box {
      display: flex;
      align-items: center;
    }
  }

  .project-header__container {
    display: flex;
    width: 100%;
    background: #f6f6f6;
    padding: 80px 0px;
    justify-content: center;

    .breadcrumb__box {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;

      ul {
        display: flex;

        li {
          &::after {
            content: '>';
            margin: 0px 10px;
            color: $--gray-900;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #777;
          &.active {
            font-weight: bold;
          }
        }
      }
    }

    .title__box {
      display: flex;
      width: 100%;
      align-content: center;
      justify-content: space-between;
      margin-bottom: 70px;
      .info {
        display: flex;

        li {
          margin-right: 40px;
          &:last-child {
            margin-right: 0px;
          }
          span {
            color: #777;
            &.value {
              color: $--gray-900;
              margin-left: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .row__box {
      display: flex;
      width: 100%;
      align-content: center;
      ul {
        display: flex;
        align-content: center;
        width: 100%;
        border-top: solid 1px #161616;

        li {
          flex: 1;
          display: flex;
          flex-direction: column;
          border-bottom: solid 1px #e3e3e3;
          border-right: solid 1px #e3e3e3;
          & > header {
            background: #f2f2f2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 70px;
            border-bottom: solid 1px #e3e3e3;
            box-sizing: border-box;
            span {
              text-align: center;
              font-size: 14px;
              font-weight: bold;
            }
            em {
              margin-top: 5px;
              font-size: 12px;
              font-weight: bold;
              color: #777;
            }
          }
          .value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 70px;
            background: #fff;
            span {
              font-size: 14px;
              font-weight: normal;
            }
            em {
              font-size: 12px;
            }

            .button__box {
              display: flex;
              button {
                min-width: 56px;
                height: 30px;
                border-radius: 8px;
                border: solid 1px #161616;
                background: #fff;
                margin-right: 4px;
                &:last-child {
                  margin-right: 0px;
                }
                &.main {
                  color: $--main-500;
                  border: solid 1px #b19ff9;
                  background-color: #f6f3ff;
                }
              }
            }
          }
        }
      }
    }

    .button-list__box {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .stat__container {
    display: flex;
    margin-top: 47px;
    width: 100%;
    ul {
      display: flex;
      width: 100%;
      li {
        flex: 1;
        border-radius: 8px;
        border: solid 1px #e3e3e3;
        margin-right: 16px;
        padding: 20px;
        box-sizing: border-box;
        &:last-child {
          margin-right: 0px;
        }

        .title__box {
          margin-bottom: 20px;
        }

        .value__box {
          display: flex;

          .vluae-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            &.no-border {
              &:first-child {
                border-right: none;
              }
            }
            &:first-child {
              border-right: solid 1px #e3e3e3;
              padding-right: 10px;
            }
            &:last-child {
              margin-left: 10px;
              border: none;
            }
            & > header {
              margin-bottom: 10px;
              span {
                font-size: 14px;
              }
            }
            .credit__box {
              display: flex;
              align-items: flex-end;
              margin-bottom: 5px;
              .red {
                color: #d73b3b;
              }
              .blue {
                color: #304ee3;
              }

              em {
                font-size: 12px;
                color: #777;
                margin-left: 6px;
              }
            }

            .caption__box {
              height: 20px;
              align-items: flex-end;
              display: flex;
              em {
                color: #777;
                font-size: 12px;
              }
              b {
                font-size: 12px;
                margin: 0px 2px;
              }
            }
          }
        }
      }
    }
  }
}

.online-terms__box {
  display: flex;
  padding: 12px;
  box-sizing: border-box;
  overflow-y: auto;
  flex-direction: column;

  h1 {
    margin: 8px 0px;
    font-size: 18px;
  }
  strong {
    margin: 8px 0px;
    font-size: 16px;
  }

  p {
    margin: 8px 0px;
    white-space: pre-line;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $--grey-500;
  }

  li {
    font-size: 14px;
    color: $--grey-500;
  }
  table {
    border: solid 1px #d1d1d1;
    margin-bottom: 12px;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    p {
      word-break: keep-all;
    }
    thead {
      background: #dbdbdb;
      th {
        text-align: center;
      }
    }
    tbody {
      td {
        padding: 0px 8px;
        border-right: solid 1px #d1d1d1;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
