// @import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

// * {
//   font-family: 'NanumSquare', sans-serif;
//   line-height: normal;
// }
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* {
  // font-family: 'NanumSquare', sans-serif;
  font-family: 'Pretendard', sans-serif !important;
  line-height: inherit;
  // line-height: normal;
}

html {
  font-size: 16px;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 16px;
}
h5 {
}

span,
b {
  font-size: 1rem;
  color: $--gray-900;
}



@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
}
button {
  font-family: 'Pretendard', sans-serif !important;
}