.table-inquiry {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px $--gray-600;
    box-sizing: border-box;
    tr {
      th {
        font-family: 'Pretendard', sans-serif;
        height: 48px;
        box-sizing: border-box;
        font-size: 16px;
        color: $--gray-900;
        border-bottom: solid 1px $--gray-200;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #F7FAFC;
      }
      td {
        font-family: 'Pretendard', sans-serif;
        height: 48px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--grey-500;
        border-bottom: solid 1px $--gray-200;

        &.Complete {
          color: $--main-500;
        }
        &:nth-child(1) {
        }
        &:nth-child(2) {
          color: $--main-500;
        }
        &:nth-child(3) {
          color: $--gray-600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 600px;
        }
        &:nth-child(4) {
        }
        &:nth-child(5) {
        }
      }
    }
  }
}

.table-paging {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  align-items: center;
  .page__box {
    display: flex;

    .MuiPagination-root {
      height: 50px;

      .MuiPagination-ul {
        height: 100%;
        li {
          &:first-child {
            button {
              width: 76px;
              border-left: solid 1px $--gray-200;
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
              padding-left: 8px;
            }
          }
          &:last-child {
            button {
              width: 76px;
              border-right: solid 1px $--gray-200;
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
              padding-right: 8px;
            }
          }

          button {
            opacity: 1 !important;
            color: $--grey-500;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-top: solid 1px $--gray-200;
            border-bottom: solid 1px $--gray-200;
            border-right: solid 1px $--gray-200;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
            border-radius: 0px;
            font-size: 14px;
            &.Mui-selected {
              background-color: $--gray-100;
              color: $--gray-600;
            }
            &.Mui-disabled {
              .prev,
              .next {
                opacity: 0.38;
              }
            }
          }
          .MuiPaginationItem-ellipsis {
            color: $--grey-500;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-top: solid 1px $--gray-200;
            border-bottom: solid 1px $--gray-200;
            border-right: solid 1px $--gray-200;
            box-sizing: border-box;
            padding: 0px;
            margin: 0px;
            border-radius: 0px;
            line-height: 50px;
          }
        }
      }
    }
  }
  .button__box {
    display: flex;
    position: absolute;
    right: 0px;
  }
}

.table-methodology {
  display: flex;
  width: 100%;
  & > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px;
      border-bottom: solid 1px $--gray-200;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;

      .img__box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 270px;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-height: 210px;
        }
      }

      .caption__box {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;
        .title {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          h2 {
            color: $--gray-600;
          }

          .version {
            margin-left: 16px;
            display: flex;
            align-items: center;
            span {
              white-space: nowrap;
              display: flex;
              align-items: center;
              &.new {
                font-weight: bold;
                color: $--main-500;
                &::after {
                  display: flex;
                  content: '';
                  height: 14px;
                  width: 1px;
                  background: #d8d8d8;
                  margin-left: 9px;
                  margin-right: 16px;
                }
              }
              font-size: 13px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #777;
            }
          }
        }
        .intro {
          margin-top: 16px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;

          p {
            font-size: 16px;
            line-height: 1.5;
            color: $--gray-600;
          }
        }
        .attribute {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0px;
          }

          & > header {
            min-width: 160px;
            span {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              color: $--grey-500;
            }
          }
          .value {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--gray-600;
          }
        }
      }

      .button__box {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 40px;
        right: 0px;
      }
    }
  }
}

.table-methodology-history {
  display: flex;
  width: 100%;

  & > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > li {
      width: 100%;
      display: flex;
      flex-direction: column;
      min-height: 50px;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 40px;
      box-sizing: border-box;

      & > header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
      }

      .attribute__list {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > li {
          width: 100%;
          display: flex;
          align-items: center;
          min-height: 50px;
          border-bottom: solid 1px $board-border-color;
          box-sizing: border-box;
          &:first-child {
            border-top: solid 1px $--gray-600;
          }
          & > header {
            height: 100%;
            display: flex;
            align-items: flex-start;
            min-width: 180px;
            background: $board-bg-color;
            padding: 20px;
            box-sizing: border-box;
            border-right: solid 1px $board-border-color;
            span {
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $--gray-600;
            }
          }
          .value {
            box-sizing: border-box;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 20px;
            span {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $--gray-600;
            }

            p {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              line-height: 1.5;
              color: $--gray-600;
            }
          }
        }
      }
    }
  }
}

.table-project {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px #2D3748;
    box-sizing: border-box;
    tr {
      th {
        font-family: 'Pretendard', sans-serif;
        font-weight: bold;
        height: 30px;
        line-height: 1.2;
        padding: 5px 0;
        box-sizing: border-box;
        font-size: 14px;
        color: #2D3748;
        background: #F7FAFC;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #F7FAFC;
      }
      td {
        font-family: 'Pretendard', sans-serif;
        padding: 10px;
        height: 85px;
        min-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--gray-900;
        border-bottom: solid 1px $board-border-color;
        word-break: keep-all;
        .title-2line {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          justify-content: center;
          p {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .status__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-family: 'Pretendard', sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: $--gray-900;

            &.date {
              font-size: 12px;
              color: #777;
            }
            &.active {
              color: #dd3939;
            }
          }
          em {
            cursor: pointer;
            font-size: 14px;
            color: #4591cf;
            text-decoration: underline;
          }
        }

        .button__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            width: fit-content;
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        button {
          min-width: 56px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          padding: 0px 4px;
          border-radius: 8px;
          border: solid 1px $--gray-900;
          font-size: 12px;
          color: $--gray-900;
          background: #fff;

          &.main {
            color: $--main-500;
            border: solid 1px #b19ff9;
            background-color: #f6f3ff;
          }
        }
      }
    }
  }
}

.table-recruit {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px #E9EAEB;
    box-sizing: border-box;
    tr {
      th {
        font-family: 'Pretendard', sans-serif;
        font-weight: 400;
        height: 46px;
        line-height: 1.2;
        padding: 5px 0;
        box-sizing: border-box;
        font-size: 14px;
        color: #535862;
        background: #FAFAFA;
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #F7FAFC;
      }
      td {
        font-family: 'Pretendard', sans-serif;
        padding: 20px;
        height: 76px;
        min-height: 60px;
        box-sizing: border-box;
        font-size: 14px;
        border-bottom: solid 1px $board-border-color;
        word-break: keep-all;
        .title-2line {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          overflow: hidden;
          width: 100%;
          justify-content: center;
          p {
            display: block;
            display: -webkit-box;
            max-width: 100%;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .status__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            font-family: 'Pretendard', sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: $--gray-900;

            &.date {
              font-size: 12px;
              color: #777;
            }
            &.active {
              color: #dd3939;
            }
          }
          em {
            cursor: pointer;
            font-size: 14px;
            color: #4591cf;
            text-decoration: underline;
          }
        }

        .button__box {
          display: flex;
          flex-direction: column;
          align-items: center;

          button {
            width: fit-content;
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        button {
          min-width: 56px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          padding: 0px 4px;
          border-radius: 8px;
          border: solid 1px $--gray-900;
          font-size: 12px;
          color: $--gray-900;
          background: #fff;

          &.main {
            color: $--main-500;
            border: solid 1px #b19ff9;
            background-color: #f6f3ff;
          }
        }
      }
    }
  }
}

.table-project-history {
  display: flex;
  width: 100%;
  thead {
    border-top: solid 1px $--gray-900;
    box-sizing: border-box;
    tr {
      th {
        font-weight: bold;
        height: 58px;
        box-sizing: border-box;
        font-size: 14px;
        padding: 0px;
        color: $--gray-900;
        background: #f6f6f6;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: #F7FAFC;
      }
      td {
        padding: 0px;
        height: 70px;
        box-sizing: border-box;
        font-size: 14px;
        color: $--gray-900;
        border-right: solid 1px $board-border-color;
        border-bottom: solid 1px $board-border-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
