.page-header {
  // bg-image-
  &.bg-image-project {
    background: url('../images/header/page-header-project.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-methodology {
    background: url('../images/header/page-header-methodology.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-reduction {
    background: url('../images/header/page-header-reduction.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-methodology-registry {
    background: url('../images/header/page-header-methodology-registry.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-project-registry {
    background: url('../images/header/page-header-project-registry.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-credit-registry {
    background: url('../images/header/page-header-credit-registry.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-credit {
    background: url('../images/header/page-header-credit.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-consultation {
    background: url('../images/header/page-header-consultation.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-document {
    background: url('../images/header/page-header-document.png');
    background-position: center center;
    background-size: cover; 
  }
  &.bg-image-standard {
    background: url('../images/header/page-header-standard.png');
    background-position: center center;
    background-size: cover; 
  }
}

.form-breadcrumbs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background: url('../images/sub-kv@3x.png');
  background-size: cover;
  height: 240px;
  position: relative;
  left: 0px;

  &.methodology {
    background: url('../images/sub-kv-1@3x.png');
    background-position: center center;
    background-size: cover;
  }

  &.project {
    background: url('../images/sub-kv-2@3x.png');
    background-position: center center;
    background-size: cover;
  }

  &.reduction {
    background: url('../images/sub-kv-3@3x.png');
    background-position: center center;
    background-size: cover;
  }
  &.credit {
    background: url('../images/sub-kv-4@3x.png');
    background-position: center center;
    background-size: cover;
  }
  &.mypage {
    background: url('../images/sub-kv-5@3x.png');
    background-position: center center;
    background-size: cover;
  }

  .content__wraperr {
    display: flex;
    width: 100%;
    max-width: $wrapper-min-width;
    min-width: $wrapper-min-width;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    .breadcrumbs__box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      ul {
        display: flex;
        li {
          display: flex;
          margin-right: 10px;
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            margin-left: 10px;
            display: flex;
            content: '>';
            color: $--gray-0;
          }
          &.on {
            span {
              font-weight: bold;
            }
          }
          span {
            cursor: pointer;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $--gray-0;
          }
        }
      }
    }
    .title__box {
      display: flex;
      align-items: center;

      h1 {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-0;
      }
    }
  }
}

.form-small-breadcrumbs {
  display: flex;
  ul {
    display: flex;
    li {
      display: flex;
      margin-right: 10px;
      &:last-child {
        &::after {
          display: none;
        }
      }
      &::after {
        margin-left: 10px;
        display: flex;
        content: '>';
        color: $--grey-500;
      }
      &.on {
        span {
          font-weight: bold;
        }
      }
      span {
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }
    }
  }
}

.form-text-input {
  display: flex;
  flex-direction: column;
  &.board {
    flex-direction: row;
    height: 70px;
    align-items: center;
    border-bottom: solid 1px $board-border-color;
    box-sizing: border-box;
    & > header {
      min-width: 175px;
      background: $board-bg-color;
      height: 100%;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }
    .input__box {
      padding: 10px;
      box-sizing: border-box;
    }
  }

  &.point {
    & > header {
      span {
        font-weight: bold;
        // color: $--gray-900;
      }
    }
  }

  &.round {
    .input__box {
      input {
        border-radius: 25px;
        border: solid 1px $--gray-300;
        padding: 0px 24px;
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }

  &.action {
    flex-direction: row;
    min-height: 57px;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;

    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }

    .input__box {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      background: #fff;
      input {
        height: 100%;
        font-size: 14px;
        padding: 0px 20px;
        &:disabled {
          border: none;
          background: transparent;
          color: $--gray-900;
        }
        &::placeholder {
          color: $--gray-900;
        }
      }
    }
  }
  &.main-color {
    .input__box {
      input {
        color: $--main-500 !important;
      }
    }
  }

  &.readOnly {
    input {
      cursor: unset;
    }
  }
  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .input__box {
    display: flex;
    width: 100%;
    position: relative;
    input {
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      border: solid 1px $--gray-300;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--gray-900;

      &:disabled {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }
      &[type='password'] {
        font-family: 'arial';
      }
      &::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $--gray-400;
      }
      &.error {
        border-color: $--red-500;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .eye__box {
      position: absolute;
      display: flex;
      right: 16px;
      top: 12px;
    }
  }

  .unit__box {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    align-items: center;
    margin-right: 16px;
    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
      height: fit-content;
    }
  }
  .helper-text {
    margin-top: 12px;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: $--grey-500;
      white-space: pre-line;
    }
  }
}

.form-text-area {
  display: flex;
  flex-direction: column;

  &.point {
    & > header {
      span {
        font-weight: normal;
        color: $--gray-900;
      }
    }
  }

  &.board {
    flex-direction: row;
    min-height: 70px;
    align-items: center;
    border-bottom: solid 1px $board-border-color;
    box-sizing: border-box;
    & > header {
      min-width: 175px;
      background: $board-bg-color;
      height: 100%;
      margin-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      span {
        font-weight: bold;
      }
      .max-size {
        margin-top: 5px;
      }
    }
    .textarea__box {
      height: 420px;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  &.action {
    flex-direction: row;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;
    max-height: 160px;

    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }

    .textarea__box {
      max-height: 160px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: #fff;
      textarea {
        font-size: 14px;
        &:disabled {
          border: none;
          background: transparent;
          padding: 0px;
        }
      }
    }
  }

  &.readOnly,
  &.disabled {
    textarea {
      background: #f4f4f4;
    }
  }

  & > header {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }

    .max-size {
      display: flex;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--grey-500;
    }
  }

  .textarea__box {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    height: 240px;
    box-sizing: border-box;
  }
  textarea {
    border: solid 1px $--gray-300;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $--gray-900;
    line-height: 1.5;
  }
}

.form-stepper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.round {
    border-radius: 8px;
    overflow: hidden;
    ul {
      li {
        &.on {
          border-radius: 8px;
        }
      }
    }
  }

  &.round-writing {
    border-radius: 8px;
    overflow: hidden;
    position: sticky;
    z-index: 2;
    top: 0px;
    ul {
      li {
        &.on {
          border-radius: 8px;
        }
      }
    }
  }

  ul {
    display: flex;
    width: 100%;

    li {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      background-color: $--gray-100;
      height: 56px;
      span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }

      &.on {
        background-color: $--main-500;

        span {
          color: $--gray-0;
        }
      }
    }
  }
}

.form-check-box {
  display: flex;
  align-items: center;

  &.terms {
    .ico__box {
      margin-right: 16px;
    }
    .label__box {
      span {
        font-weight: bold;
        color: $--gray-900;
        font-size: 16px;
      }
      em {
        font-size: 16px;
      }
    }
  }

  &.disabled {
    .ico__box {
      pointer-events: none;
      opacity: 0.8;
    }
    .label__box {
      pointer-events: none;
      opacity: 0.8;
    }
  }
  .ico__box {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .label__box {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }
    em {
      &.required {
        color: $--red-500;
      }
      margin-left: 8px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }
  }
}

.form-file-uploader {
  display: flex;
  flex-direction: column;

  &.point {
    & > header {
      span {
        font-weight: normal;
        color: $--gray-900;
      }
    }

    .input__wrapper {
      background-color: transparent !important;
    }
  }
  &.file {
    .input__wrapper {
      .preview__box {
        height: 50px;
        flex: 1;
        border-radius: 8px;
        border: solid 1px $--gray-300;

        .empty__box {
          background-color: transparent;
        }
      }
      .add__box {
        .info-button {
          height: 50px !important;
        }
      }
    }
  }

  &.action {
    flex-direction: row;
    min-height: 57px;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;

    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }
    .input__wrapper {
      padding: 0px;
      box-sizing: border-box;
      width: 100%;
      background: #fff !important;

      .preview__box {
        height: 100%;
        width: 100%;
        border: none;

        .close__box {
          top: 17px;
          right: 0px;
        }
        .file__box {
          display: flex;
          align-items: center;
          padding: 0px 20px;
          span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $--main-500;
          }
        }
      }
      .add__box {
        justify-content: center;
        margin-right: 20px;
      }
    }
  }

  &.disabled {
    .input__wrapper {
      background-color: #f4f4f4;
      color: #777;
    }
  }
  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .input__wrapper {
    display: flex;
    .preview__box {
      width: 128px;
      height: 128px;
      position: relative;
      .empty__box {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: $--gray-100;
      }

      .img__box {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        border: solid 1px $--gray-300;
        // &:hover {
        //   border: solid 1px $--gray-900;
        // }
        img {
          width: 100%;
          cursor: pointer;
        }
      }
      .file__box {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 16px;
        padding-right: 32px;
        box-sizing: border-box;

        span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .close__box {
        position: absolute;
        right: 8px;
        top: 12px;
        svg {
          color: $--gray-400;
        }
      }
    }
    .add__box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 16px;

      .helper-text {
        margin-top: 12px;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.3;
          letter-spacing: normal;
          color: $--grey-500;
          white-space: pre-line;
        }
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-address {
  display: flex;
  flex-direction: column;

  &.content {
    width: 100%;
    p.address {
      margin: 20px 0;
    }
    .address-value__box {
      flex-direction: row;
      align-items: center;

      .zonecode {
        width: 160px;
        margin-right: 8px;
        input {
          width: 100%;
        }
      }
      .address {
        margin-top: 0px;
        margin-right: 8px;
        flex: 1;
      }
      .detail {
        margin-top: 0px;
        margin-right: 0px;
        flex: 1;
      }
    }
    &.readOnly {
      button {
        opacity: 0.3;
      }
      .address-value__box {
        input {
          border: solid 1px $--gray-300;
          background-color: #f4f4f4;
          color: $--grey-500;
        }
      }
    }
  }
  &.point {
    & > header {
      span {
        font-weight: normal;
        color: $--gray-900;
      }
    }
  }

  &.disabled {
    .address-value__box {
      input {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }
    }
  }

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .radio__box {
    margin-bottom: 12px;
  }
  .address-value__box {
    display: flex;
    flex-direction: column;

    input {
      height: 50px;
      border-radius: 8px;
      border: solid 1px $--gray-300;
      background-color: $--gray-0;
      padding: 0px 16px;
      box-sizing: border-box;
    }
    .zonecode {
      display: flex;
      input {
        width: 204px;
      }
    }
    .postcode {
      margin-right: 24px;
      input {
        width: 204px;
      }
    }
    .address {
      display: flex;
      margin-top: 12px;
      input {
        width: 100%;
      }
    }
    .detail {
      display: flex;
      margin-top: 12px;

      input {
        width: 100%;
      }
    }
  }

  .kakao-map__box {
    display: flex;
    margin-top: 20px;
    background: #efefef;
    border-right: 8px;
    overflow: hidden;

    #kakao-map {
      width: 944px;
      height: 400px;
      border-right: 8px;
      overflow: hidden;
    }
  }
}

.form-file-multiple-uploader {
  display: flex;
  flex-direction: column;

  &.board {
    flex-direction: row;
    min-height: 70px;
    align-items: center;
    border-bottom: solid 1px $board-border-color;
    box-sizing: border-box;
    & > header {
      min-width: 175px;
      background: $board-bg-color;
      height: 100%;
      margin-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .label__box {
        display: flex;
        flex-direction: column;

        span {
          font-weight: bold;
        }
        em {
          margin-top: 5px;
        }
      }
      .sub-label__box {
      }
    }
    .list__box {
      display: flex;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }

  &.disabled {
    opacity: 0.8;
    .list__box {
      li {
        pointer-events: none;
      }
    }
  }

  &.image {
    .list__box {
      display: grid;
      border-radius: 8px;
      background-color: #f6f6f6;
      padding: 20px;
      box-sizing: border-box;
      grid-template-columns: repeat(5, 1fr);
      gap: 8px;
      min-height: 170px;
      li {
        height: 130px;
        max-height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        width: 100%;

        &.render {
          height: 100%;
          margin-bottom: 0px;
          padding: 0px;
        }

        &.add-image {
          cursor: pointer;
          background: url('../images/empty-thumb@3x.png');
          background-size: 100% 100%;

          &.loading {
            pointer-events: none;
          }

          .button__box {
            border-radius: 8px;
            background-color: #606060;
            height: 38px;
            display: flex;
            justify-content: center;
            padding: 13px 24px;
            box-sizing: border-box;
            span {
              font-size: 14px;
              font-weight: normal;
              line-height: 1;
              font-style: normal;
              letter-spacing: normal;
              color: $--gray-0;
            }
          }
        }
      }
    }
  }

  & > header {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;

    .label__box {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }
      em {
        font-size: 14px;
        color: $--grey-500;
        font-weight: normal;
        margin-left: 5px;
      }
    }
    .sub-label__box {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--grey-500;
      }
    }
    span {
      &.sub-label {
        color: $--grey-500;
      }
    }
  }
  .list__box {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }

      &.render {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 16px;
        height: 50px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        border: solid 1px $--gray-300;
        background-color: $--gray-0;
        color: $--gray-900;

        .file__box {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 30px;
          span {
            cursor: pointer;
          }
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }

        .img__box {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          overflow: hidden;
          img {
            width: 100%;
          }
        }

        .delete__box {
          cursor: pointer;
          display: flex;
          position: absolute;
          right: 7px;
          top: 7px;
        }
      }
    }
  }
  .input__box {
    display: none;
  }
}

.form-custom {
  display: flex;
  flex-direction: column;

  &.point {
    & > header {
      span {
        font-weight: bold;
        color: $--gray-900;
      }
    }
  }

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }
  }
}

.form-radio-box {
  display: flex;
  flex-direction: column;

  &.column {
    & > header {
      span {
        font-size: 16px;
        color: $--gray-900;
      }
    }
    & > ul {
      flex-direction: column;
      li {
        margin-right: 0px;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  &.board {
    flex-direction: row;
    min-height: 70px;
    align-items: center;
    border-bottom: solid 1px $board-border-color;
    box-sizing: border-box;
    & > header {
      min-width: 175px;
      background: $board-bg-color;
      height: 100%;
      margin-bottom: 0px;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .label__box {
        display: flex;
        flex-direction: column;

        span {
          font-weight: bold;
        }
        em {
          margin-top: 5px;
        }
      }
      .sub-label__box {
      }
    }
    & > ul {
      display: flex;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }

  &.disabled {
    opacity: 0.8;
    ul {
      li {
        pointer-events: none;
      }
    }
  }

  &.action {
    flex-direction: row;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;
    min-height: 57px;

    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      text-align: center;
      span {
        font-weight: bold;
      }
    }

    & > ul {
      padding: 0px 20px;
      box-sizing: border-box;
    }
  }

  & > header {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
    em {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--grey-500;
      margin-top: 12px;
    }
    .sub-label {
      margin-top: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }
  & > ul {
    display: flex;
    li {
      display: flex;
      cursor: pointer;
      align-items: center;
      margin-right: 40px;
      &:last-child {
        margin-right: 0px;
      }
      .ico__box {
        margin-right: 5px;
      }
      .text__box {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }
    }
  }
}

.form-dropdown-input {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: solid 1px $--gray-300;
  background-color: $--gray-0;
  height: 50px;
  padding: 0px 24px;
  box-sizing: border-box;

  .input__box {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  select {
    cursor: pointer;
    height: 100%;
    flex: 1;
    padding: 0px;
    margin: 0px;
    border: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $--gray-900;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: transparent;
    background-image: url('../icon/arrow-down@3x.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: 16px 16px;

    &:focus-visible {
      outline: none;
    }
  }

  .border-bar {
    display: flex;
    width: 1px;
    height: 30px;
    background: #d8d8d8;
    margin-left: 8px;
    margin-right: 16px;
  }
  input {
    flex: 3;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    border: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $--gray-900;

    &::placeholder {
      color: $--gray-400;
    }
  }
}

.form-select-box {
  display: flex;
  flex-direction: column;

  &.round {
    .select__box {
      select {
        font-size: 14px;
        border-radius: 25px;
        background-position-x: 90%;
        background-position-y: 50%;
      }
    }
  }

  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .sub-label__box {
    display: flex;
    margin-bottom: 14px;

    p {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #777;
      white-space: pre-line;
      line-height: 1.5;
    }
  }

  .select__box {
    display: flex;
    width: 100%;
    height: 50px;

    select {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      border: solid 1px $--gray-300;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background: transparent;
      background-image: url('../icon/arrow-down@3x.png');
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 50%;
      background-size: 24px 24px;

      option {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }

      &:focus-visible {
        outline: none;
      }
      &:disabled {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }
    }
  }
}

.form-toast-editor {
  display: flex;
  flex-direction: column;
  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .editor__box {
    width: 100%;
  }
}

.form-toast-viewer {
  display: flex;
  flex-direction: column;
  &.readOnly {
    &.content {
      .editor__box {
        background: #f4f4f4;
      }
    }
  }
  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }
  &.content {
    .editor__box {
      border: solid 1px $--gray-300;
      // background-color: #f4f4f4;
      background: transparent;
      border-radius: 8px;
      padding: 16px;
      box-sizing: border-box;
      min-height: 560px;
    }
  }
  .editor__box {
    width: 100%;
  }
}

.form-select-evidence {
  display: flex;
  flex-direction: column;
  width: 100%;

  .evidence-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }
    }

    .radio__box {
      margin-bottom: 12px;
    }
  }
}

.form-date-picker {
  display: flex;
  flex-direction: column;

  &.project {
    & > header {
      justify-content: flex-start;
    }
  }
  &.action {
    background: #fff;
    flex-direction: row;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;
    min-height: 57px;
    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }

    .input__box {
      max-width: 240px;
      padding: 10px 20px;

      input {
      }
    }
  }

  & > header {
    display: flex;
    margin-bottom: 14px;
    // justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .input__box {
    display: flex;
    width: 100%;
    position: relative;
    input {
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      border: solid 1px $--gray-300;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--gray-900;

      &:disabled {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }

      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $--gray-400;
      }
      &.error {
        border-color: $--red-500;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.form-select-monitoring {
  display: flex;
  flex-direction: column;
  width: 100%;

  .monitoring-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        // color: $--gray-900;
      }
    }

    .radio__box {
      margin-bottom: 12px;
    }
  }
}

.form-more-monitoring {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .add-button__box {
    display: flex;
    justify-content: center;
  }

  .monitoring-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }

    .title__box {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    .radio__box {
      margin-bottom: 12px;
    }
  }
}

.form-more-file {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > header {
    display: flex;
    margin-bottom: 14px;
    justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }
  }

  .add-button__box {
    display: flex;
    justify-content: center;
  }

  .file-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }

    .title__box {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    .radio__box {
      margin-bottom: 12px;
    }
  }
}

.form-file-multiple-viewer {
  margin-top: 12px;
  & > header {
    margin-bottom: 12px;
  }
}

.form-commma-number-input {
  display: flex;
  flex-direction: column;

  &.board {
    flex-direction: row;
    height: 70px;
    align-items: center;
    border-bottom: solid 1px $board-border-color;
    box-sizing: border-box;
    & > header {
      min-width: 175px;
      background: $board-bg-color;
      height: 100%;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }
    .input__box {
      padding: 10px;
      box-sizing: border-box;
    }
  }

  &.point {
    & > header {
      span {
        font-weight: bold;
        color: $--gray-900;
      }
    }
  }

  &.round {
    .input__box {
      input {
        border-radius: 25px;
        border: solid 1px $--gray-300;
        padding: 0px 24px;
        font-size: 14px;
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }

  &.action {
    flex-direction: row;
    min-height: 57px;
    border-bottom: solid 1px #e3e3e3;
    border-right: solid 1px #e3e3e3;

    & > header {
      min-width: 100px;
      background-color: #f2f2f2;
      border-right: solid 1px #e3e3e3;
      margin-bottom: 0px;
      justify-content: center;
      span {
        font-weight: bold;
      }
    }

    .input__box {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      background: #fff;
      input {
        height: 100%;
        font-size: 14px;
        padding: 0px 20px;
        &:disabled {
          border: none;
          background: transparent;
          color: $--gray-900;
        }
      }
    }
  }
  &.main-color {
    .input__box {
      input {
        color: $--main-500 !important;
      }
    }
  }

  &.readOnly {
    input {
      cursor: unset;
    }
  }
  & > header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      // color: $--gray-900;
    }
  }

  .input__box {
    display: flex;
    width: 100%;
    position: relative;
    input {
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0px 16px;
      box-sizing: border-box;
      border-radius: 8px;
      border: solid 1px $--gray-300;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $--gray-900;

      &:disabled {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }
      &[type='password'] {
        font-family: 'arial';
      }
      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $--gray-400;
      }
      &.error {
        border-color: $--red-500;
      }
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .eye__box {
      position: absolute;
      display: flex;
      right: 16px;
      top: 12px;
    }
  }

  .unit__box {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    align-items: center;
    margin-right: 16px;
    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
      height: fit-content;
    }
  }
  .helper-text {
    margin-top: 12px;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: $--grey-500;
      white-space: pre-line;
    }
  }
}

.address-search__box {
  min-height: 500px;
  .search-box {
    padding: 20px;
    .detail {
      display: flex;
    }
  }
  .result__list {
    margin-top: 20px;
    .result-item {
      display: flex;
      align-items: center;
      padding: 8px;
      p {
        font-size: 16px;
        margin-right: 10px;
      }
      .button {
        cursor: pointer;
        padding: 8px;
        border: 1px solid $--main-500;
        color: $--main-500;
      }
    }
  }
}

.MuiMenu-paper {
  &::-webkit-scrollbar-track {
    border-radius: 8px;
    box-shadow: inset 0px 0px 5px white;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar {
    width: 0.8rem;
  }
}

.MuiButton-root {
  text-transform: none !important;
}