.basic-tab {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid 1px #161616;

  & > ul {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 2px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 40px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background-color: $--gray-200;
      span {
        font-size: 18px;
      }

      &.active {
        background: #555b67;
        span {
          color: #fff;
        }
      }
    }
  }
}
