.public-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: $wrapper-min-width;
    max-width: $wrapper-min-width;
  }
  .public-button__container {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 50%;
    max-width: $wrapper-min-width + 80;
    width: 100%;
  }
}
