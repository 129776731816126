.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .banner__container {
    width: 100%;
    position: relative;
    display: flex !important;
    justify-content: center;
    height: 640px;
    overflow: hidden;
    .title__box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: auto;
      height: 640px;
      z-index: 100;
      width: 100%;
      max-width: 1260px;
      min-width: 1260px;
      h1 {
        font-size: 40px;
        line-height: 1.3;
        white-space: pre-wrap;
        color: #fff;
        margin-bottom: 20px;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);

      }
      p {
        margin-top: 8px;
        font-size: 20px;
        color: #fff;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);

      }
      .button-wrapper {
        padding: 20px 0;
      }
    }
    .slider-container {
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      .slick-track {
        display: flex;
      }
      .slick-slide > div {
        height: 100%;
        min-height: 640px;
        position: relative;
        display: flex !important;
        justify-content: center;
        position: relative;
      }
      .image__box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 640px;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        filter: brightness(0.8);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }    
  }
 
  
  .list__container {
    display: flex;
    width: 100%;
    border-radius: 16px;
    padding: 40px 0px;
    box-shadow: 2px 2px 32px 0 rgba(0, 0, 0, 0.11);
    background-color: $--gray-0;
    box-sizing: border-box;
    margin-top: 40px;
    ul {
      width: 100%;
      display: flex;
      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        text-align: center;
        h4 {
          margin-bottom: 5px;
          margin-top: 15px;
          color: $--main-500;
          font-size: 24px;
          font-weight: 600;
          padding-bottom: 8px;
        }
        span {
          // color: #222;
          line-height: 1.5;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .certification__container {
    display: flex;
    width: 100%;
    margin-top: 60px;
    flex-direction: column;
    padding-bottom: 60px;
    ul {
      margin-top: 30px;
      display: flex;
      width: 100%;
      li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        text-align: center;
        span {
          margin-top: 20px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          position: absolute;
          right: 0px;
          display: flex;
          content: '';
          top: 80px;
          right: -10px;
          background-image: url('../icon/arrow_grey.svg');
          width: 12px;
          height: 20px;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
  .footer__wrapper {
    background-color: $--main-100;
    width: 100%;
    padding: 100px;
  }
  .utils__container {
    width: 100%;
    max-width: 1260px;
    margin: auto;
  }
}
