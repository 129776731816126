.basic-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: $--main-500;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $--gray-0;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  };
}

.square-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    background-color: $--main-500;
    color: $--gray-0;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}

// 보더만 라인
// 백그라운드 화이트
.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: solid 1px $--main-500;
    background-color: transparent;
    color: $--main-500;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}

.secondary-arrow-button {
  @extend .secondary-button;
  border: solid 1px $--main-500;
  border-radius: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  button {
    border: 0;
    font-weight: 600;
  }
  img {
    display: block;
  }
}

.info-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: solid 1px $--gray-300;
    background-color: transparent;
    color: $--gray-900;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}

.secondary-square-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: solid 1px $--main-500;
    background-color: transparent;
    color: $--main-500;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}

.secondary-square-small-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: solid 1px $--main-500;
    background-color: transparent;
    color: $--main-500;
    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}

.info-square-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    border: solid 1px $--gray-300;
    background-color: transparent;
    color: $--gray-900;

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  img {
    display: none;
  }
}
