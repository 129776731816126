.board-inquiry {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: solid 1px $--gray-600;

  .title__box {
    display: flex;
    min-height: 48px;
    align-items: center;
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: solid 1px $--gray-200;
    padding: 10px 0px;

    .text__box {
      display: flex;
      flex: 1;
      align-items: center;
      box-sizing: border-box;
      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--main-500;
        margin-right: 10px;
        white-space: nowrap;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
        line-height: 1.5;
      }
    }
    .status__box {
      display: flex;
      min-width: 200px;
      justify-content: space-between;
      margin-left: 20px;
      em {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }

      span {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-900;
      }
    }
  }

  .file__box {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    box-sizing: border-box;
    border-bottom: solid 1px $--gray-200;
    & > header {
      display: flex;
      align-items: center;
      margin-right: 20px;
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-600;
      }
    }
    .file-list__box {
      display: flex;
      flex: 1;

      & > ul {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        box-sizing: border-box;
        li {
          display: flex;
          align-items: center;
          min-height: 28px;
          span {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $--main-500;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  & > .content__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 38px 16px;
    box-sizing: border-box;
    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $--gray-600;
      white-space: pre-line;
    }
  }

  .reply__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      span {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--gray-600;
      }
    }
    .content__box {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $--gray-600;
        white-space: pre-line;
      }
    }
  }
}

.board-accordion {
  display: flex;
  flex-direction: column;
  .accordion__box {
    display: flex;
    flex-direction: column;
    border-top: solid 1px $--grey-500;
    width: 100%;
    overflow: hidden;

    .empty__box {
      display: flex;
      height: 48px;
      border-bottom: solid 1px $--gray-200;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--grey-500;
      }
    }
    .notice-summary {
      display: flex;
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      &.top {
        background-color: $--gray-100;
      }

      .seq {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        margin-right: 32px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--grey-500;
      }
      .title {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 32px;
        span {
          min-width: 0;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
      .date {
        widows: 70px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--grey-500;
      }
      .category {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        margin-right: 32px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $--main-500;
      }
    }

    //mui custom

    .MuiAccordion-root {
      &.Mui-expanded {
        margin: 0px;
      }
      &.top {
        .MuiAccordionSummary-root {
          background-color: $--gray-100;
        }
      }
    }

    .MuiAccordionSummary-root {
      border-bottom: solid 1px $--gray-200;
      &.Mui-expanded {
        min-height: 48px;
        .notice-summary {
          .title {
            span {
              color: $--main-500;
            }
          }
        }
      }
    }
    .MuiAccordionSummary-content {
      overflow: hidden;
      padding: 0px;
      margin: 0px;
    }
  }
}
