.main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: $wrapper-min-width;
  max-width: $wrapper-min-width;

  padding: 0px;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 30px;
  .left__container {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    span {
      color: $--grey-500;
    }
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      li {
        display: flex;
        margin-right: 32px;

        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $--grey-500;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    .caption__box {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        margin-bottom: 8px;
        span {
          font-size: 14px;
          color: rgb(156 163 175);
        }
      }
    }
  }

  .right__container {
    display: flex;
    height: 100%;
    align-items: center;

    select {
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0px 16px;
      box-sizing: border-box;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
      background: transparent;
      background-image: url('../icon/arrow-down@3x.png');
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 50%;
      background-size: 24px 24px;
      border-radius: 25px;
      background-color: $--gray-200;
      width: 176px;
      height: 32px;
      border: none;
      option {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #161616;
      }

      &:focus-visible {
        outline: none;
      }
      &:disabled {
        border: solid 1px $--gray-300;
        background-color: #f4f4f4;
        color: $--grey-500;
      }
    }
  }
}
