.home-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: $--gray-0;
  .content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: $wrapper-min-width;
    max-width: $wrapper-min-width;
  }
  .header__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: transparent;
    position: absolute;
    z-index: 999;
    .main-header {
      background: transparent;
      .left__container .nav__box > ul > li > span {
        color: #fff;
      }
      .left__container .nav__box > ul > li {
        &.on {
          border-color: #fff;
        }
      }

      .right__container ul li span {
        color: #fff;
      }
      .right__container ul li {
        &.on {
          border-color: #fff;
        }
      }

      .sub-menu__container {
        background: transparent;
        .sub-nav__list {
          li {
            span {
              color: #fff;
            }
            &:hover {
              border-color: #fff;
            }
          }
        }
      }
    }
  }

  .content__box {
    width: 100%;
  }

  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: $header-height;
    background-color: $--gray-0;
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
}
