.contact-privacy__modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 640px;
  overflow: hidden;

  & > header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px 30px 20px;
    box-sizing: border-box;
  }
  .title__box {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 12px;
    span {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
    }
    border-bottom: solid 1px $--grey-500;
  }

  .content__box {
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;

    h1 {
      margin: 8px 0px;
      font-size: 18px;
    }
    strong {
      margin: 8px 0px;
      font-size: 16px;
    }

    p {
      margin: 8px 0px;
      white-space: pre-line;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: $--grey-500;
    }

    li {
      font-size: 14px;
      color: $--grey-500;
    }

    table {
      border: solid 1px #d1d1d1;
      margin-bottom: 12px;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      p {
        word-break: keep-all;
      }
      thead {
        background: #dbdbdb;
        th {
          text-align: center;
        }
      }
      tbody {
        td {
          padding: 0px 8px;
          border-right: solid 1px #d1d1d1;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

.methodology-history__modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 960px;
  overflow-y: auto;
  padding-bottom: 40px;

  & > header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 64px;
    min-height: 64px;
    padding: 0px 20px 0px 40px;
    box-sizing: border-box;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  }

  .table__box {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 40px;
    box-sizing: border-box;
  }
}

.project-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }
    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }
        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
    b {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
      article:nth-child(2n) {
        border-right: none;
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.project-document__modal {
  display: flex;
  max-height: 880px;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  .header__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    min-height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    position: fixed;
    background: #fff;
    z-index: 10;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    left: 0px;
    span {
      margin-left: 20px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .step-left-button__box {
    position: absolute;
    left: -65px;
    top: 50%;
  }
  .step-right-button__box {
    position: absolute;
    right: -65px;
    top: 50%;
  }
  .document__box {
    display: flex;
    flex-direction: column;

    width: 1136px;
    margin-top: 64px;
  }
}

.modal-reduction-select {
  display: flex;
  flex-direction: column;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .project__box {
    margin-top: 40px;
    padding: 0px 40px;
    box-sizing: border-box;

    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .button__box {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
}

.reduction-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }
    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }
        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
    b {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
      article:nth-child(2n) {
        border-right: none;
      }
      .monitor__box {
        display: flex;
        overflow: hidden;
        & > article {
          &:last-child {
            border-right: solid 1px #e3e3e3;
          }
        }
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.credit-action-modal {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  overflow-y: auto;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 40px;
    box-sizing: border-box;
    height: 64px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--gray-0);
  }

  .guide-phone__container {
    display: grid;
    grid-template-columns: 440px 1fr;
    padding: 40px;
    box-sizing: border-box;
    padding-bottom: 0px;

    .text__box {
      display: flex;
      flex-direction: column;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      em {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $--gray-900;
        margin-bottom: 7px;
      }
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }
    .phone__box {
      display: flex;
      border-bottom: solid 1px #e3e3e3;
      padding-bottom: 20px;

      .text {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        em {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;
          margin-bottom: 7px;
        }
        span {
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-900;

          small {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #777;
          }
        }
      }
    }
  }

  .guide-link__container {
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
  }

  .guide-text__container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: solid 1px #e3e3e3;
    margin: 0px 40px;
    b {
      margin-bottom: 10px;
    }
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #777;
    }
  }

  .dialogue-text__container {
    display: flex;
    margin: 45px 40px 20px 40px;
  }

  .dialogue-em__container {
    border-radius: 8px;
    background-color: #f6f6f6;
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;

      &.one {
        grid-template-columns: 1fr 1fr;
      }
      article:nth-child(2n) {
        border-right: none;
      }
      .monitor__box {
        display: flex;
        overflow: hidden;
        & > article {
          &:last-child {
            border-right: solid 1px #e3e3e3;
          }
        }
      }
    }
  }

  .comment__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    &.bg {
      background: #f6f6f6;
    }
    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      span {
        margin-left: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #777;
      }
    }

    .form-list {
      display: grid;
      grid-template-columns: 1fr;
      border-top: solid 1px $--gray-900;

      article {
        border-right: none;
      }
    }
  }

  .button__container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .launch__container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;

    & > header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .form-list {
      display: grid;
      grid-template-columns: 490px 1fr;
      border-top: solid 1px $--gray-900;
    }
  }
}

.modal-credit-assignee {
  display: flex;
  flex-direction: column;
  max-height: 900px;
  overflow: auto;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    min-height: 65px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-left: 40px;
    padding-right: 20px;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .searh__box {
    display: flex;
    margin-top: 40px;
    align-items: center;
    padding: 0px 40px;
    box-sizing: border-box;

    input {
      width: 100%;
      height: 50px;
      padding: 0px;
      margin: 0px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $--gray-900;
      border: solid 1px $--gray-300;
      border-radius: 25px;
      padding: 0px 16px;
      &::placeholder {
        color: $--gray-400;
      }
    }
  }
  .table__box {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    & > header {
      display: flex;
      padding: 0px 40px;
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .header__list {
      display: flex;
      padding: 0px 40px;
      box-sizing: border-box;
      margin-top: 16px;
      width: 100%;
      height: 58px;
      li {
        border-top: solid 1px #161616;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #f6f6f6;
        border-right: solid 1px #e3e3e3;
        &:last-child {
          border-right: none;
        }
        span {
          font-size: 14px;
          font-weight: bold;
        }
        &:first-child {
          max-width: 50px;
        }
        &:nth-child(2) {
          max-width: 240px;
        }
      }
    }

    .value__list {
      display: flex;
      padding: 0px 40px;
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      cursor: pointer;
      &:hover {
        li {
          background: #f6f3ff;
        }
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #fff;
        border-right: solid 1px #e3e3e3;
        border-bottom: solid 1px #e3e3e3;
        font-size: 14px;
        &:last-child {
          border-right: none;
        }

        &:first-child {
          max-width: 50px;
        }
        &:nth-child(2) {
          max-width: 240px;
        }

        p {
          line-height: 1.5;
        }
      }
    }
    .empty__list {
      display: flex;
      justify-content: center;
      padding: 0px 40px;
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      align-items: center;
      font-size: 14px;
      color: #777;
    }
  }

  .button__box {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
