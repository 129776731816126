.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .menu__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: $header-height;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 11;

    .content__wrapper {
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
    }
  }

  .sub-menu__container {
    position: absolute;
    top: 104px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
    height: 0px;
    transition: all 0.3s linear;
    opacity: 0;
    background-color: #fff;
    &.open {
      display: flex;
      height: $child-header-height;
      opacity: 1;
      z-index: 10;
    }
    .content__wrapper {
      align-items: flex-start;
      height: 100%;
      justify-content: center;
    }

    .sub-nav__list {
      display: flex;
      height: 100%;
      li {
        height: 100%;
        margin-right: 40px;
        display: flex;
        align-items: center;
        border-bottom: solid 4px transparent;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          & > span {
            font-weight: bold;
          }
        }

        &:hover {
          border-color: $--gray-900;
          span {
            font-weight: bold;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        span {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $--gray-900;
        }
      }
    }
  }

  .left__container {
    display: flex;
    height: 100%;
    align-items: center;
    .logo__box {
      display: flex;
      margin-right: 87px;
    }
    .nav__box {
      display: flex;
      height: 100%;
      & > ul {
        display: flex;
        height: 100%;
        & > li {
          margin-right: 36px;
          display: flex;
          position: relative;
          align-items: center;
          height: 100%;
          border-bottom: solid 4px transparent;
          box-sizing: border-box;
          &:last-child {
            margin-right: 0px;
          }
          &.on {
            border-color: $--main-500;
            & > span {
              color: $--main-500;
            }
          }

          &.active {
            & > span {
              color: $--main-500;
            }
          }
          & > span {
            cursor: pointer;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $--gray-600;
          }
        }
      }
    }
  }

  .right__container {
    display: flex;
    height: 100%;
    align-items: center;
    ul {
      display: flex;
      height: 100%;
      li {
        display: flex;
        position: relative;
        align-items: center;
        height: 100%;
        margin-right: 33px;
        border-bottom: solid 4px transparent;
        box-sizing: border-box;

        &.on {
          border-color: $--main-500;
          & > span {
            color: $--main-500;
          }
        }

        &.active {
          & > span {
            color: $--main-500;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          span {
            color: $--main-500;
          }
        }
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $--gray-600;
        }
      }
    }
  }
}

.login-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: $wrapper-min-width;
  max-width: $wrapper-min-width;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
  z-index: 100;
  .left__container {
    display: flex;
    height: 100%;
    align-items: center;
    .logo__box {
      display: flex;
      margin-right: 90px;
    }
  }

  .right__container {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
