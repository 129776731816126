.pac-container {
  z-index: 99999999999 !important;
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 480px !important;
  border-top: none !important;

  @media screen and (min-width: global(breakpoint-desktop)) {
    top: calc(100% - 156px) !important;
    bottom: 0 !important;
    margin-top: 0 !important;
  }

  .pac-item {
    max-width: 1216px !important;
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    cursor: pointer;

    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      max-width: 1088px !important;
    }

    @media screen and (min-width: global(breakpoint-desktop)) {
      padding: 0;
    }

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }

    & > span {
      font-size: 16px;
      line-height: 50px;

      .pac-matched {
        color: black;
        font-weight: bold;
      }
    }

    .pac-icon {
      display: none;
    }
  }

  &::after {
    height: 0 !important;
    background-image: none !important;
  }
}
