$primary-color: #03a1c8;
$second-color: #f3924b;
$error-color: #e50914;
$contrast-color: #fff;

// content
$text-color: #999;
$title-color: #333;
$placeholder-color: #b8b8b8;
$border-color: #ced4da;
$base-bg-color: #fff;

//box-shadow
$box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
$box-shadow-hover: rgba(56, 72, 157, 0.5) 0px 3px 8px;

//pople color
$--main-500: #5d3be5;
$--main-300: #ded6ff;
$--main-100: #faf9ff;
$--red-500: #f41515;
$--gray-900: #161616;
$--gray-600: #555b67;
$--grey-500: #777;
$--gray-400: #9ca3af;
$--gray-350: #bcbcbc;
// $--gray-300: #ccc;
$--gray-300: #d1d5db;
$--gray-200: #eee;
$--gray-100: #f7f7f9;
$--gray-0: #fff;

$board-bg-color: #f6f6f6;
$board-border-color: #e3e3e3;
