.ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: transparent;
  cursor: pointer;

  &.logo {
    background-image: url('./icon/logo@3x.png');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.logout {
    background-image: url('./icon/icon_logout.svg');
    width: 18px;
    height: 18px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.nav-logo-purple {
    background-image: url('./icon/logo_pople.svg');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.nav-logo-white {
    background-image: url('./icon/logo_pople_white.svg');
    width: 80px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.outer {
    background-image: url('./icon/icon_outer_link.svg');
    width: 15px;
    height: 15px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.outer_black {
    background-image: url('./icon/icon_outer_link_black.svg');
    width: 15px;
    height: 15px;
    margin-left: 8px;
    display: inline-flex;
    background-position: center;
    background-size: cover;
  }
  &.logo-white {
    background-image: url('./icon/logo-white@3x.png');
    width: 104px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.logo-small {
    background-image: url('./icon/logo@3x.png');
    width: 62px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }

  &.phone {
    background-image: url('./icon/phone@3x.png');
    width: 35px;
    height: 35px;
    background-position: center;
    background-size: cover;
  }
  &.check {
    background-image: url('./icon/check-off@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
    &.on {
      background-image: url('./icon/check-on@3x.png');
    }
    &.disabled {
      background-image: url('./icon/check-on@3x.png');
      filter: grayscale(100%) opacity(50%);
    }
  }
  &.required {
    background-image: url('./icon/required@3x.png');
    // width: 16px;
    // height: 16px;
    width: 14px;
    height: 14px;
    background-position: center;
    background-size: cover;
  }
  &.eye {
    background-image: url('./icon/eye@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.eye-off {
    background-image: url('./icon/eye-off@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }

  &.close {
    background-image: url('./icon/close@3x.png');
    width: 36px;
    height: 36px;
    background-position: center;
    background-size: cover;
  }
  &.close-small {
    background-image: url('./icon/close@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.next {
    background-image: url('./icon/next@3x.png');
    width: 16px;
    height: 16px;
    background-position: center;
    background-size: cover;
  }
  &.next-big {
    background-image: url('./icon/next@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.prev-big {
    background-image: url('./icon/next@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    transform: rotate(-180deg);
  }
  &.arrow-down {
    background-image: url('./icon/arrow-down@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.check-square-on {
    background-image: url('./icon/check-square-on@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.check-square-disabled {
    background-image: url('./icon/check-square-on@3x.png');
    filter: grayscale(100%) opacity(50%);
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.check-square {
    background-image: url('./icon/check-square-off@3x.png');
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.tri {
    background-image: url('./icon/tri@3x.png');
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: cover;
  }
  &.store {
    background-image: url('./icon/icon-store@3x.png');
    width: 20px;
    height: 17px;
    background-position: center;
    background-size: cover;
  }
  &.forward {
    background-image: url('./icon/button-foward@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.backward {
    background-image: url('./icon/button-backward@3x.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-1 {
    background-image: url('./icon/icon_main_01.svg');
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-2 {
    background-image: url('./icon/icon_main_02.svg');
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-3 {
    background-image: url('./icon/icon_main_03.svg');
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }
  &.home-illust-4 {
    background-image: url('./icon/icon_main_04.svg');
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }
  &.certification {
    display: flex;
    justify-self: center;
    align-items: center;
    background-color: #F9F9FA;
    padding: 20px;
    border-radius: 50%;
  }
  &.home-icon-0 {
    background-image: url('./icon/icon_contact_00.svg');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-1 {
    background-image: url('./icon/icon_contact_01.svg');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-2 {
    background-image: url('./icon/icon_contact_02.svg');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-icon-3 {
    background-image: url('./icon/icon_contact_03.svg');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
  }
  &.home-path {
    background-image: url('./icon/path-3@3x.png');
    width: 20px;
    height: 36px;
    background-position: center;
    background-size: cover;
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
}
