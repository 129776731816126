.login-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: $--gray-0;
  .content__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: $wrapper-min-width;
    max-width: $wrapper-min-width;
  }
  .header__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: $header-height;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    background-color: $--gray-0;
  }

  .content__box {
    width: 100%;
  }

  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: $header-height;
    margin-top: 80px;
    background-color: $--gray-0;
    border-top: solid 1px $--gray-200;
  }
}

@media only screen and (min-width: 1025) and (max-width: 1440px) {
}

@media only screen and (min-width: 766px) and (max-width: 1024px) {
}

@media only screen and (max-width: 765px) {
}
